import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/Impressum.vue')
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import('../views/Datenschutz.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash, 
        offset : { x: 0, y: 150 },
        behavior: 'smooth',
      }
    }
    else{
      window.scrollTo(0,0)
    }
  },
})

export default router

