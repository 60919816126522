// import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';

// Vue.use(Vuetify);

// export default new Vuetify({

// });

import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
// import * as mdijs from '@mdi/js'
Vue.use(Vuetify);
//Vue.use(mdiVue, {icons: mdijs}) 
const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
        light: {
          primary: '#ffffff',
          secondary:colors.grey.lighten1,
          tertiary: colors.grey.darken2,
          accent:'#E9E9E9',
          background: '#Ffffff',
          toolbar: '#FFF',
          // card: '#293B5B'
      },
    },
  },
});
export default vuetify