<template>
  <div>
    <v-img
      class="background"
      lazy-src="../assets/background-image-2.jpg"
      src="../assets/background-image-2.jpg"
      height="92.3vh"
    >
      <v-container fill-height fluid>
        <v-row align="center"
            justify="center">
          <v-spacer />
            <v-col :cols="colWidth">
              <v-card height="fit-content" min-height="17vh" class="text-center d-flex align-center justify-center">
                <v-container fill-height fluid class="card" >
                  <v-row align="center" justify="center">
                    <v-spacer />
                      <v-col cols="11">
                        IHR VERMÖGEN IN VERANTWORTUNGSVOLLEN HÄNDEN
                      </v-col>
                    <v-spacer />
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-img>
    <v-container class="row1" fluid>
    <v-row align="center" justify="center">
      <v-spacer />
      <v-col class="list1">
        <h1>
          Kontakt
        </h1>
        <p>Wipev GmbH</p>
        <!-- <p>GF:N.Peters</p> -->
        <p>Querallee 38</p>
        <p>34119 Kassel</p>
        <p><a href="Tel: 0561-22076942" style="color:#44546A; text-decoration: none;">Tel: 0561-22076942</a></p>
        <p><a href="mailto:wipevgmbh@web.de" style="color:#44546A; text-decoration: none;">Email: wipevgmbh@web.de</a></p>
       </v-col>
      <v-spacer />
    </v-row>
    </v-container>






<!-- __________________Neuer Kontakt Versuch_____________________________________________________________________________________________________________________________________________________ -->

 
    <v-container  class="row2" justify="center" fluid v-if="firstVersion">
      <v-row>
        <v-spacer v-if="listCol == 3" />
        <v-spacer />
        <v-col class="list1 marginTop" :cols="listCol">
          <h1 id="row1">Kontakt</h1>
          <br>
          <p>Wipev GmbH</p>
          <!-- <p>GF:N.Peters</p> -->
          <p>Querallee 38</p>
          <p>34119 Kassel</p>
          <p><a href="Tel: 0561-22076942" style="color:#44546A; text-decoration: none;">Tel: 0561-22076942</a></p>
          <p><a href="mailto:wipevgmbh@web.de" style="color:#44546A; text-decoration: none;">Email: wipevgmbh@web.de</a></p>
        </v-col>
        <v-spacer />
        <v-col :cols="listCol">
          <v-img class="map" lazy-src="../assets/map.png" src="../assets/map.png" height="40vh">
          </v-img>
        </v-col>
        <v-spacer />
        <v-spacer v-if="listCol == 3" />  
      </v-row>
    </v-container>


<!-- __________________Neuer Kontakt Versuch: Ende_____________________________________________________________________________________________________________________________________________________ -->









    <v-container  class="row2" justify="center" fluid v-if="firstVersion">
      <v-row>
        <v-spacer v-if="listCol == 3" />
        <v-spacer />
        <v-col class="list2" :cols="listCol">
          <h1 id="Mietverwaltung">Mietverwaltung</h1>
          <br>
          Zu unseren Tätigkeiten des Miet-Verwalters gehören die Vermietung, Durchführung und Abwicklung der Mietsverhältnisse des Eigentümers sowie Berechnung der Betriebskosten. Hierbei ist irrelevant ob der Eigentümer eine einzelne Person, Ehegatten oder Gruppen, BGB-Gesellschaften, Erbgemeinschaften oder juristische Personen sind. Die Verwaltung der Immobilie wird von uns gesamtheitlich behandelt. 
          <br>
          Maßgeblich für die Aufgaben des Miet-Verwalters ist in erster Linie der Verwaltervertrag. Erfasst sind meistens nachstehende Tätigkeiten:
          <br>
          <br>
          <br>
          <h2>Finanzielle Betreuung des Mietobjekts</h2>
          <ul>
          <li>Überwachung der Mietzahlungen sowie ggf. deren Durchsetzung im Mahnverfahren</li>
          <li>Sicherstellung der Zahlungsverpflichtungen für das Mietobjekt – soweit vom Eigentümer vertraglich übertragen – sowie ggf. Abwehr unberechtigter Forderungen</li>
          <li>Buchhaltung für alle Einnahmen und Ausgaben für das Mietobjekt</li>
          <li>Fristgerechte Abrechnung der jährlichen Betriebskosten Vermietung und Abwicklung von Mietverhältnissen (etwa Mietersuche, Prüfung der Bonität der Mieter, Mietvertragserstellung, ggf. Mietvertragsabschluss, Kautionsabwicklung, Mieterhöhungen unter Berücksichtigung des Mietspiegels, Erhöhung der Betriebskostenvorauszahlungen, ggf. Kündigung des Mieters, ggf. Entgegennahme von Mieterkündigungen)
          <li>Korrespondenz mit Vertragspartnern</li>
          </ul>
          <br>
          <br>
          <h2>Bauliche und technische Betreuung des Mietobjekts</h2>
          <ul>
          <li>Wohnungsabnahmen und Wohnungsübernahmen nebst Protokollerstellung über den Wohnungszustand</li>
          <li>Entgegennahme von Mängelanzeigen der Mieter</li>
          <li>Begehungen des Mietobjekts zur Mängelfeststellung</li>
          <li>Vergabe und Überwachung der erforderlichen Instandhaltungs- und Instandsetzungsarbeiten</li>
          <li>Wahrung von Verkehrssicherungspflichten (etwa Vergabe des Winterdienste)</li>
          </ul>
        </v-col>
        <v-spacer />
        <v-col :cols="listCol">
          <v-img class="map" lazy-src="../assets/house.png" src="../assets/house.png" >
          </v-img> 
        </v-col>
        <v-spacer />
        <v-spacer v-if="listCol == 3" />  
      </v-row>
    </v-container>


 <v-container class="row2" justify="center" fluid v-else>
      <v-row>
        <v-spacer />
        <v-col class="list2" cols="11">
          <h1 id="Mietverwaltung">Mietverwaltung</h1>
          <br>
          Zu unseren Tätigkeiten des Miet-Verwalters gehören die Vermietung, Durchführung und Abwicklung der Mietsverhältnisse des Eigentümers sowie Berechnung der Betriebskosten. Hierbei ist irrelevant ob der Eigentümer eine einzelne Person, Ehegatten oder Gruppen, BGB-Gesellschaften, Erbgemeinschaften oder juristische Personen sind. Die Verwaltung der Immobilie wird von uns gesamtheitlich behandelt. 
          <br>
          Maßgeblich für die Aufgaben des Miet-Verwalters ist in erster Linie der Verwaltervertrag. Erfasst sind meistens nachstehende Tätigkeiten:
          </v-col>
          <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
          <v-col class="list2" cols="11">
          <h2>Finanzielle Betreuung des Mietobjekts</h2>
          <ul>
          <li>Überwachung der Mietzahlungen sowie ggf. deren Durchsetzung im Mahnverfahren</li>
          <li>Sicherstellung der Zahlungsverpflichtungen für das Mietobjekt – soweit vom Eigentümer vertraglich übertragen – sowie ggf. Abwehr unberechtigter Forderungen</li>
          <li>Buchhaltung für alle Einnahmen und Ausgaben für das Mietobjekt</li>
          <li>Fristgerechte Abrechnung der jährlichen Betriebskosten Vermietung und Abwicklung von Mietverhältnissen (etwa Mietersuche, Prüfung der Bonität der Mieter, Mietvertragserstellung, ggf. Mietvertragsabschluss, Kautionsabwicklung, Mieterhöhungen unter Berücksichtigung des Mietspiegels, Erhöhung der Betriebskostenvorauszahlungen, ggf. Kündigung des Mieters, ggf. Entgegennahme von Mieterkündigungen)
          <li>Korrespondenz mit Vertragspartnern</li>
          </ul>
        </v-col>
        <v-spacer />
        </v-row>
        <v-row class="mt-10 mb-6">
          <v-spacer />
            <v-col cols="11">
          <v-img class="map" lazy-src="../assets/house.png" src="../assets/house.png" height="35vh">
          </v-img> 
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
          <h2>Bauliche und technische Betreuung des Mietobjekts</h2>
          <ul>
          <li>Wohnungsabnahmen und Wohnungsübernahmen nebst Protokollerstellung über den Wohnungszustand</li>
          <li>Entgegennahme von Mängelanzeigen der Mieter</li>
          <li>Begehungen des Mietobjekts zur Mängelfeststellung</li>
          <li>Vergabe und Überwachung der erforderlichen Instandhaltungs- und Instandsetzungsarbeiten</li>
          <li>Wahrung von Verkehrssicherungspflichten (etwa Vergabe des Winterdienste)</li>
          </ul>
        </v-col>

        <v-spacer />
      </v-row>
    </v-container>



    <v-container class="row2" justify="center" fluid v-if="firstVersion">
      <v-row>
        <v-spacer v-if="listCol == 3" />
        <v-spacer />
        <v-col :cols="listCol">
          <v-img class="map" lazy-src="../assets/woman.png" src="../assets/womannew.png" height="100%" >
          </v-img> 
        </v-col>
        <v-col class="list2" :cols="listCol">
          <h1 id="Maklertaetigkeiten">Maklertätigkeiten</h1>
          <br>
          Als qualifizierte Makler nehmen wir Ihnen viele Aufgaben beim Kauf und Verkauf von Immobilien ab, und stehen Ihnen bei Fragen zu vertraglichen Aspekten und rechtlichen Problemen zur Seite. 
          <br>
          <br>
          <br>
          <h2>Kauf von Immobilien:</h2>
          <ul>
          <li>Aufsuchen der passenden Immobilien</li>
          <li>Berichterstattung der angehenden Suchaktivitäten</li>
          <li>Vermittlung sowie Organisation von Besichtigungsterminen</li>
          <li>Vermittlung von Experten (z. B. bei Schadstoffverdacht)</li>
          <li>Verkaufsverhandlung</li>
          <li>Regelkonforme Ausarbeitung des Kaufvertrages</li>
          </ul>
          <br>
          <br>
          <h2>Verkauf von Immobilien:</h2>
          <ul>
          <li>Vermittlung von: Rechtsanwalt , Notar, Bausachverständige und Gutachter</li>
          <li>Organisation von Besichtigungsterminen</li>
          <li>Überprüfung der Mieterbonität</li>
          <li>Verhandeln mit interessierten Mietern</li>
          </ul>
        </v-col>
        <v-spacer />
        <v-spacer />
      </v-row>
    </v-container>



     <v-container class="row2 marginBottom" justify="center" fluid v-else>
      <v-row>
        <v-col class="list2" :cols="listCol">
          <h1 id="Maklertaetigkeiten">Maklertätigkeiten</h1>
          <br>
          Als qualifizierte Makler nehmen wir Ihnen viele Aufgaben beim Kauf und Verkauf von Immobilien ab, und stehen Ihnen bei Fragen zu vertraglichen Aspekten und rechtlichen Problemen zur Seite. 
        </v-col>
      </v-row>
      <v-row class="mt-10 mb-6">
        <v-col :cols="listCol">
          <v-img class="map" lazy-src="../assets/woman.png" src="../assets/woman.png" height="35vh" >
          </v-img> 
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>Kauf von Immobilien:</h2>
          <ul>
          <li>Aufsuchen der passenden Immobilien</li>
          <li>Berichterstattung der angehenden Suchaktivitäten</li>
          <li>Vermittlung sowie Organisation von Besichtigungsterminen</li>
          <li>Vermittlung von Experten (z. B. bei Schadstoffverdacht)</li>
          <li>Verkaufsverhandlung</li>
          <li>Regelkonforme Ausarbeitung des Kaufvertrages</li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10"> 
          <h2>Verkauf von Immobilien:</h2>
          <ul>
          <li>Bewertung der Immobilien durch Ermittlung der Sachwert-, Ertragswert-, und Vergleichswertverfahren.</li>
          <li>Vertragsgerechte Bauberatung</li>
          <li>Vermittlung von Experten (z.B bei Entrümpelung)</li>
          <li>Organisation von Besichtigungsterminen</li>
          <li>Überprüfung der Mieterbonität</li>
          <li>Verhandeln mit interessierten Mietern</li>
          </ul>
        </v-col>
        <v-spacer />
        <v-spacer />
      </v-row>
    </v-container>



    <!-- ____________________________________________________old map____________________________________________________ -->



    <!-- <v-container class="row4" fluid>
      <v-row>
        <v-img class="map" lazy-src="../assets/map.png" src="../assets/map.png" height="40vh">
        </v-img>  
      </v-row>
    </v-container> -->



     <!-- ____________________________________________________Ende_____________________________________________________-->

    
  </div>
  
</template>

<script>
  export default {
    computed: {
      colWidth () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 11;
          case 'sm': return 10;
          case 'md': return 8;
          case 'lg': return 4;
          case 'xl': return 4;
          default: return 4;
        }
      },
      listCol () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 11;
          case 'sm': return 10;
          case 'md': return 8;
          case 'lg': return 3;
          case 'xl': return 3;
          default: return 3;
        }
      },

       firstVersion () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return false;
          case 'sm': return false;
          case 'md': return false;
          case 'lg': return true;
          case 'xl': return true;
          default: return true;
        }
      },
    },
  }
</script>