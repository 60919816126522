<template>
  
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      height="100"
    >
      <!-- <div class="d-flex align-center"> -->
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 headerImg"
          src="../src/assets/Wipev-Logo.png"
          transition="scale-transition"
          width="540"
          v-if="bigHeader"
          v-on:click="goHome()"
        />

        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 headerImg"
          contain
          src="../src/assets/Wipev-Logo-XS.png"
          transition="scale-transition"
          width="300px"
          height="60"
          v-else
          v-on:click="goHome()"
        />
        <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
      <!-- </div> -->

      <v-spacer></v-spacer>

      <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          v-on:click="selectPage(index)"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>

      <!-- <v-toolbar>
      </v-toolbar> -->

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-main>
 <router-view/>
     

      <v-container class="footer" fluid>
      <v-row class="center" justify="center">
        <v-spacer />
          <!-- <v-col cols="1"> -->
            <!-- <p class="Impressum">Impressum</p>
          </v-col> -->
          <!-- <h3>|</h3> -->
          <v-col class="white--text" align="center">
            <!-- <p class="Datenschutz">Datenschutz</p> -->
            <router-link to="/impressum"> Impressum </router-link> |
            <router-link to="/datenschutz"> Datenschutz </router-link>
          </v-col>
        <v-spacer />
      </v-row>
    </v-container>
    </v-main>
  </v-app>
</template>

<style>
@import "./css/styles.css";
</style>

<script>

export default {
  name: 'App',

  data: () => ({
    items:[{name:"Mietverwaltung", url:"#Mietverwaltung"}, {name:"Maklertätigkeiten", url:"#Maklertaetigkeiten"}]
  }),

  methods:{
    selectPage(index){
      this.$router.push("/")
      this.$router.push({name: '', hash: this.items[index].url})
        // var element = this.$refs[this.items[index].url];
        // var top = element.offsetTop;

    // window.scrollTo(0, top);
    },
    goHome(){
      this.$router.push("/")
    }
  },

  computed: {
      bigHeader () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return false;
          case 'sm': return true;
          case 'md': return true;
          case 'lg': return true;
          case 'xl': return true;
          default: return true;
        }
      },
    },  
};
</script>
